import * as actionTypes from '../actions/report.actions'

const initialState = {
    report: '',
    reportLoaded: false,
}

const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_REPORT: {
            return {
                ...state,
                reportLoaded: false,
            }
        }
        case actionTypes.LOAD_REPORT_SUCCESS: {
            return {
                ...state,
                report: action.report,
                reportLoaded: true,
            }
        }
        case actionTypes.LOAD_REPORT_FAILURE: {
            return {
                ...state,
                report: '<h3>Brak raportu do wyświetlenia</h3>',
                reportLoaded: true,
            }
        }

        default:
            return state
    }
}

export default reportReducer
