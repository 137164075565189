import React from 'react'
import {
    loadProjectDetails,
    deleteProject,
    saveProjectChanges,
    saveCostChanges,
    addCost,
    archiveCost,
} from '../../store/actions/projects.actions'
import {loadPositions, loadProjectStages, loadProjectTypes} from '../../store/actions/types.actions'
import {loadEmployees} from '../../store/actions/employees.actions'
import {connect} from 'react-redux'
import TwoPartTitle from '../../ui/TwoPartTitle/TwoPartTitle'
import ProjectDetailsHeader, {
    archivedOptions,
} from '../../components/ProjectDetails/ProjectDetailsHeader/ProjectDetailsHeader'
import ProjectDetailsCosts from '../../components/ProjectDetails/ProjectDetailsCosts/ProjectDetailsCosts'
import {hideModal, showModal} from '../../store/actions/modals.actions'
import ProjectDetailsNewCost from '../../components/ProjectDetails/ProjectDetailsNewCost/ProjectDetailsNewCost'
import Spinner from '../../components/Spinner/Spinner'
import {toast} from 'react-toastify'
import {history} from '../../helpers/history'

class ProjectDetails extends React.Component {
    constructor(props) {
        super(props)
        const projectId = this.props.match.params.id
        this.state = {
            costToDelete: null,
            projectId: parseInt(projectId),
        }

        this.props.loadProjectDetails(projectId)
    }

    componentDidMount() {
        this.props.loadPositions()
        this.props.loadEmployees()
        this.props.loadProjectStages()
        this.props.loadProjectTypes()
    }

    onProjectSaveSuccess = () => {
        this.props.hideModal()
        toast.success('Project saved successfully')
    }

    onProjectSaveError = () => {
        this.props.hideModal()
    }

    onProjectSave = project => {
        this.props.showModal({open: true, message: 'Saving...'}, 'spinner')
        this.props.saveProjectChanges(
            this.state.projectId,
            {
                name: project.name,
                projectType: project.projectType.value,
                budget: project.budget,
                projectStage: project.projectStage.value,
                archived: project.archived.value,
            },
            this.onProjectSaveSuccess,
            this.onProjectSaveError,
        )
    }

    onDeleteCostSuccess = () => {
        this.props.hideModal()
        toast.success('Cost archived successfully')
    }

    onDeleteCostError = () => {
        this.props.hideModal()
    }

    archiveCost = costId => {
        this.props.showModal({open: true, message: 'Deleting...'}, 'spinner')
        this.props.archiveCost(costId, this.onDeleteCostSuccess, this.onDeleteCostError)
    }

    onCostArchive = cost => {
        this.props.showModal(
            {
                open: true,
                title: '',
                message: 'Are You sure You want to archive this employee?',
                confirm: () => this.archiveCost(cost.id),
            },
            'confirm',
        )
    }

    onAddCostSuccess = () => {
        this.props.hideModal()
        this.props.loadProjectDetails(this.state.projectId)
        toast.success('Cost added successfully')
    }

    onAddCostError = () => {
        this.props.hideModal()
    }

    onAddCost = cost => {
        this.props.showModal({open: true, message: 'Adding...'}, 'spinner')
        this.props.addCost(this.state.projectId, cost, this.onAddCostSuccess, this.onAddCostError)
    }

    onUpdateCost = cost => {
        this.props.saveCostChanges(cost)
    }

    onDeleteProjectSuccess = () => {
        this.props.hideModal()
        history.replace('/projects')
        toast.success('Project deleted successfully')
    }

    onDeleteProjectError = () => {
        this.props.hideModal()
    }

    deleteProject = () => {
        this.props.showModal({open: true, message: 'Deleting...'}, 'spinner')
        this.props.deleteProject(this.state.projectId, this.onDeleteProjectSuccess, this.onDeleteProjectError)
    }

    onProjectDelete = () => {
        this.props.showModal(
            {
                open: true,
                message: 'Are You sure You want to delete this project?',
                confirm: () => this.deleteProject(),
            },
            'confirm',
        )
    }

    render() {
        if (
            !this.props.projectLoaded ||
            !this.props.positionsLoaded ||
            !this.props.employeesLoaded ||
            !this.props.projectTypesLoaded ||
            !this.props.projectStagesLoaded
        ) {
            return <Spinner />
        }

        if (!this.props.project) {
            return <h4 className="mt-7 align-c">This project does not exist</h4>
        }

        return (
            <div className="page-margin my-4">
                <div>
                    <h1 className="mb-5">Projects</h1>
                    <div className="d-flex a-center j-between">
                        <TwoPartTitle firstPart="Project" secondPart={this.props.project.name}></TwoPartTitle>
                        <button
                            className="action-button action-button-rectangle action-button-pink"
                            onClick={() => this.onProjectDelete()}>
                            Delete project
                        </button>
                    </div>
                    <ProjectDetailsHeader
                        projectTypes={this.props.projectTypes}
                        projectStages={this.props.projectStages}
                        project={{
                            name: this.props.project.name,
                            projectType: {
                                value: this.props.project.projectType.id,
                                label: this.props.project.projectType.name,
                            },
                            budget: this.props.project.budget,
                            projectStage: {
                                value: this.props.project.projectStage.id,
                                label: this.props.project.projectStage.name,
                            },
                            archived: archivedOptions[this.props.project.archived],
                        }}
                        onSave={this.onProjectSave}
                    />

                    {this.props.positionsLoaded && this.props.employeesLoaded && (
                        <>
                            <ProjectDetailsCosts
                                positions={this.props.positions}
                                employees={this.props.employees}
                                costs={this.props.project.costs}
                                update={this.onUpdateCost}
                                onCostArchive={this.onCostArchive}
                            />
                            <ProjectDetailsNewCost
                                employees={this.props.employees}
                                positions={this.props.positions}
                                add={this.onAddCost}
                            />
                        </>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        positions: state.types.positions,
        positionsLoaded: state.types.positionsLoaded,

        employees: state.employees.employeeNames,
        employeesLoaded: state.employees.employeesLoaded,

        project: state.projects.project,
        projectLoaded: state.projects.projectLoaded,

        projectTypes: state.types.projectTypes,
        projectTypesLoaded: state.types.projectTypesLoaded,

        projectStages: state.types.projectStages,
        projectStagesLoaded: state.types.projectStagesLoaded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProjectDetails: id => dispatch(loadProjectDetails(id)),
        loadPositions: () => dispatch(loadPositions()),
        loadEmployees: () => dispatch(loadEmployees()),
        loadProjectStages: () => dispatch(loadProjectStages()),
        loadProjectTypes: () => dispatch(loadProjectTypes()),

        deleteProject: (projectId, onSuccess, onError) => dispatch(deleteProject(projectId, onSuccess, onError)),
        saveProjectChanges: (projectId, project, onSuccess, onError) =>
            dispatch(saveProjectChanges(projectId, project, onSuccess, onError)),

        addCost: (projectId, cost, onSuccess, onError) => dispatch(addCost(projectId, cost, onSuccess, onError)),

        archiveCost: (costId, onSuccess, onError) => dispatch(archiveCost(costId, onSuccess, onError)),
        saveCostChanges: cost => dispatch(saveCostChanges(cost)),

        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails)
