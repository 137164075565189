import {history} from '../../helpers'
import axios from 'axios'
import {toast} from 'react-toastify'
import {handleError} from '../../helpers'
import {store} from '../../App'

const baseUrl = process.env.REACT_APP_API_URL

export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'

export const GET_ME = 'GET_ME'
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS'
export const GET_ME_FAILURE = 'GET_ME_FAILURE'

export const PASSWORD_RECOVER = 'PASSWORD_RECOVER'
export const PASSWORD_RECOVER_SUCCESS = 'PASSWORD_RECOVER_SUCCESS'
export const PASSWORD_RECOVER_FAILURE = 'PASSWORD_RECOVER_FAILURE'

export const PASSWORD_RESET = 'PASSWORD_RESET'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE'

const mapUser = user => {
    return {
        id: user.id,
        username: user.username,
        email: user.email,
        role: user.role.name,
        leaveDays: user.vacationDays,
        company: user.company ? {id: user.company.id, name: user.company.name} : null,
        companyUrl: user.company.logo ? user.company.logo : null,
    }
}

export const login = (login, password) => {
    const credentials = {
        identifier: login,
        password: password,
    }
    return dispatch => {
        dispatch({type: 'LOGIN'})
        axios
            .post(`${baseUrl}/auth/local`, credentials)
            .then(response => {
                const mapped = mapUser(response.data.user)
                dispatch(
                    loginSuccess({
                        ...mapped,
                        token: response.data.jwt,
                    }),
                )
            })
            .catch(error => dispatch(loginFailure(error.response.data.error)))
    }
}

export const loginSuccess = user => {
    localStorage.setItem('user', JSON.stringify(user))
    history.replace('/dashboard')
    return {
        type: LOGIN_SUCCESS,
        user: user,
    }
}

export const loginFailure = error => {
    handleError(error)
    return {
        type: LOGIN_FAILURE,
        error,
    }
}

export const logout = error => {
    localStorage.removeItem('user')
    history.push('/login')
    return {
        type: LOGOUT,
        error: error,
    }
}

export const recoverPassword = email => {
    const body = {
        email: email,
    }
    return dispatch => {
        dispatch({type: 'PASSWORD_RECOVER'})
        axios
            .post(`${baseUrl}/auth/forgot-password`, body)
            .then(() => {
                dispatch(recoverPasswordSuccess())
            })
            .catch(error => dispatch(recoverPasswordFailure(error.response.data.error)))
    }
}

export const recoverPasswordSuccess = () => {
    toast.info('Check e-mail adress.')
    return {
        type: PASSWORD_RECOVER_SUCCESS,
    }
}

export const recoverPasswordFailure = error => {
    handleError(error)
    return {
        type: PASSWORD_RECOVER_SUCCESS,
    }
}

export const resetPassword = (password, passwordConfirmation, code) => {
    const body = {
        password: password,
        passwordConfirmation: passwordConfirmation,
        code: code,
    }

    return dispatch => {
        dispatch({type: 'PASSWORD_RESET'})
        axios
            .post(`${baseUrl}/auth/reset-password`, body)
            .then(() => {
                dispatch(resetPasswordSuccess())
            })
            .catch(error => dispatch(resetPasswordFailure(error.response.data.error)))
    }
}

export const resetPasswordSuccess = () => {
    toast.info('Password changed! Please log in.')
    history.push('/login')
    return {
        type: PASSWORD_RESET_SUCCESS,
    }
}

export const resetPasswordFailure = error => {
    handleError(error)
    return {
        type: PASSWORD_RESET_FAILURE,
    }
}

export const getMe = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: 'GET_ME'})
        axios
            .get(`${baseUrl}/users/me`, config)
            .then(response => {
                const user = mapUser(response.data)
                dispatch(getMeSuccess(user))
            })
            .catch(error => dispatch(getMeFailure(error.response.data.error)))
    }
}

export const getMeSuccess = user => {
    const memoryUser = store.getState().auth.user

    const merged = {
        ...memoryUser,
        ...user,
    }
    localStorage.setItem('user', JSON.stringify(merged))
    return {
        type: GET_ME_SUCCESS,
        user: merged,
    }
}

export const getMeFailure = error => {
    handleError(error)
    return {
        type: GET_ME_FAILURE,
        error,
    }
}
