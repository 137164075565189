import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Route, Redirect, Router} from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import Login from './pages/Login/Login'
import Menu from './components/Menu/Menu'
import History from './pages/History/History'
import Timesheet from './pages/Timesheet/Timesheet'
import Employees from './pages/Employees/Employees'
import PasswordRecovery from './pages/PasswordRecovery/PasswordRecovery'
import PasswordReset from './pages/PasswordReset/PasswordReset'
import Projects from './pages/Projects/Projects'
import ProjectDetails from './pages/ProjectDetails/ProjectDetails'
import ModalsContainer from './ui/ModalsContainer'
import {hideModal} from './store/actions/modals.actions'
import {getMe, logout} from './store/actions/authentication.actions'
import Vacations from './pages/Vacations/Vacations'
import axios from 'axios'
import Report from './pages/Report/Report'
import {history} from './helpers'

class AppRoutes extends Component {
    componentDidMount() {
        axios.interceptors.response.use(
            response => response,
            error => {
                const {status} = error.response
                if (status === 401) {
                    logout()
                }
                return Promise.reject(error)
            },
        )

        if (this.props.loggedIn) {
            this.props.getMe()
        }
    }

    render() {
        return (
            <Router history={history}>
                <div className="App">
                    <ModalsContainer hideModal={this.props.hideModal} />
                    <Menu />
                    <div className="layout">
                        <Route exact path="/">
                            {this.props.loggedIn ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
                        </Route>
                        <Route path="/login" exact component={Login} />
                        <Route path="/recovery" exact component={PasswordRecovery} />
                        <Route path="/reset" exact component={PasswordReset} />
                        <PrivateRoute path="/dashboard" exact component={Timesheet} authed={this.props.loggedIn} />
                        <PrivateRoute path="/vacations" exact component={Vacations} authed={this.props.loggedIn} />
                        <PrivateRoute path="/history" exact component={History} authed={this.props.loggedIn} />
                        <PrivateRoute path="/projects" exact component={Projects} authed={this.props.loggedIn} />
                        <PrivateRoute path="/employees" exact component={Employees} authed={this.props.loggedIn} />
                        <PrivateRoute path="/report" exact component={Report} authed={this.props.loggedIn} />
                        <PrivateRoute
                            path="/projects/:id"
                            exact
                            component={ProjectDetails}
                            authed={this.props.loggedIn}
                        />
                    </div>
                </div>
            </Router>
        )
    }
}
const mapStateToProps = state => ({
    loggedIn: state.auth.loggedIn,
})

const mapDispatchToProps = dispatch => {
    return {
        hideModal: () => dispatch(hideModal()),
        logout: () => dispatch(logout()),
        getMe: () => dispatch(getMe()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes)
