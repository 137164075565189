import React from 'react'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import styles from './projectDetailsNewCost.module.scss'
import SVG from 'react-inlinesvg'
import {hideModal, showModal} from '../../../store/actions/modals.actions'
import {connect} from 'react-redux'
import {sortString} from '../../../helpers/sorter/sorter'

class ProjectDetailsNewCost extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: null,
            employeeLevel: null,
            internal: 0,
            external: 0,
        }
        this.employeeInput = React.createRef()
        this.positionInput = React.createRef()
    }

    handleChange = e => {
        const {name, value} = e.target
        this.setState({[name]: value})
    }

    handleEmployeeChange = value => {
        this.setState({
            ...this.state,
            user: value,
        })
    }

    handlePositionChange = value => {
        this.setState({
            ...this.state,
            employeeLevel: value,
        })
    }

    addCost() {
        const cost = {
            user: this.state.user.value,
            employeeLevel: this.state.employeeLevel.value,
            internal: this.state.internal,
            external: this.state.external,
        }
        this.props.add(cost)
    }

    closeModal() {
        this.props.hideModal()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }

    sortData() {
        this.props.employees.sort(sortString('label'))
    }

    render() {
        this.sortData()

        return (
            <div className={styles.container}>
                <div className={styles.controlContainer}>
                    <CustomSelect
                        searchable
                        ref={this.employeeInput}
                        className={'form-control'}
                        selected={{value: '', label: ''}}
                        options={this.props.employees}
                        onChange={this.handleEmployeeChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <CustomSelect
                        searchable
                        ref={this.positionInput}
                        className={'form-control'}
                        selected={{value: '', label: ''}}
                        options={this.props.positions}
                        onChange={this.handlePositionChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <input
                        type="number"
                        min="0"
                        className={'form-control'}
                        name="internal"
                        value={this.state.internal}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <input
                        type="number"
                        min="0"
                        className={'form-control'}
                        name="external"
                        value={this.state.external}
                        onChange={this.handleChange}
                    />
                </div>
                <div>
                    <button
                        className="action-button action-button-circle action-button-pink align-right"
                        onClick={() => this.addCost()}
                        disabled={!this.state.user || !this.state.employeeLevel}>
                        <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsNewCost)
