import * as actionTypes from '../actions/projects.actions'
import clone from 'ramda/src/clone'

const initialState = {
    projectsLoaded: false,
    projectLoaded: true,
    projectAdded: true,
    projects: [],
}

const projectsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_PROJECTS:
            return {
                ...state,
                projectsLoaded: false,
            }

        case actionTypes.LOAD_PROJECTS_SUCCESS:
            return {
                ...state,
                projects: action.projects,
                projectsLoaded: true,
            }
        case actionTypes.LOAD_PROJECTS_FAILURE:
            return {
                ...state,
                projects: [],
                projectsLoaded: true,
            }

        case actionTypes.LOAD_PROJECT_DETAILS: {
            return {
                ...state,
                projectLoaded: false,
            }
        }
        case actionTypes.LOAD_PROJECT_DETAILS_SUCCESS: {
            return {
                ...state,
                project: action.project,
                projectLoaded: true,
                projectUpdated: true,
            }
        }
        case actionTypes.LOAD_PROJECT_DETAILS_FAILURE: {
            return {
                ...state,
                projectLoaded: true,
                projectUpdated: true,
            }
        }

        case actionTypes.DELETE_PROJECT_SUCCESS: {
            return {
                ...state,
                projectsLoaded: true,
            }
        }

        case actionTypes.ADD_PROJECT: {
            return {
                ...state,
                projectAdded: false,
            }
        }
        case actionTypes.ADD_PROJECT_SUCCESS: {
            let newProjects = clone(state.projects)
            const project = action.project
            const newProject = {
                id: project.id,
                ...project.attributes,
                projectStage: {
                    ...project.attributes.projectStage.data,
                    ...project.attributes.projectStage.data.attributes,
                },
                projectType: {
                    ...project.attributes.projectType.data,
                    ...project.attributes.projectType.data.attributes,
                },
            }

            newProjects.push(newProject)

            return {
                ...state,
                projects: newProjects,
                projectAdded: true,
            }
        }
        case actionTypes.ADD_PROJECT_FAILURE: {
            return {
                ...state,
                projectAdded: true,
            }
        }

        case actionTypes.SAVE_PROJECT_CHANGES: {
            return {
                ...state,
                projectUpdated: false,
            }
        }
        case actionTypes.SAVE_PROJECT_CHANGES_SUCCESS: {
            return {
                ...state,
                projectUpdated: true,
            }
        }

        case actionTypes.LOAD_USER_PROJECTS: {
            return {
                ...state,
                projectsLoaded: false,
            }
        }
        case actionTypes.LOAD_USER_PROJECTS_SUCCESS: {
            return {
                ...state,
                projects: action.projects,
                projectsLoaded: true,
            }
        }
        case actionTypes.LOAD_USER_PROJECTS_FAILURE: {
            return {
                ...state,
                projectsLoaded: true,
                projects: [],
            }
        }

        case actionTypes.DELETE_COST: {
            return {
                ...state,
            }
        }
        case actionTypes.DELETE_COST_SUCCESS: {
            const project = clone(state.project)
            project.costs = project.costs.filter(cost => cost.id !== action.costId)

            return {
                ...state,
                project,
            }
        }
        case actionTypes.DELETE_COST_FAILURE: {
            return {
                ...state,
                projectLoaded: true,
            }
        }

        case actionTypes.ADD_COST: {
            return {
                ...state,
                projectLoaded: false,
            }
        }
        case actionTypes.ADD_COST_SUCCESS: {
            return {
                ...state,
                projectLoaded: true,
            }
        }
        case actionTypes.ADD_COST_FAILURE: {
            return {
                ...state,
                projectLoaded: true,
            }
        }

        case actionTypes.ARCHIVE_COST: {
            return {
                ...state,
            }
        }
        case actionTypes.ARCHIVE_COST_SUCCESS: {
            const project = clone(state.project)
            const newCosts = project.costs.map(cost => {
                if (cost.id === action.costId) {
                    cost.archived = true
                }
                return cost
            })

            project.costs = newCosts

            return {
                ...state,
                project,
            }
        }
        case actionTypes.ARCHIVE_COST_FAILURE: {
            return {
                ...state,
            }
        }

        default:
            return state
    }
}

export default projectsReducer
