import React, {Component} from 'react'
import PropTypes from 'prop-types'

class TextInput extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value: this.props.value,
        }
    }
    static propTypes = {
        value: PropTypes.string,
        onUpdate: PropTypes.func.isRequired,
    }

    static defaultProps = {
        value: '',
    }

    handleChange = e => {
        this.setState({
            ...this.state,
            value: e.target.value,
        })
    }

    getValue() {
        return this.control.value
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const {value, onUpdate, ...rest} = this.props
        return (
            <input
                {...rest}
                value={this.state.value}
                key="control"
                ref={node => (this.control = node)}
                type="text"
                onChange={this.handleChange}
                onBlur={() => onUpdate(this.getValue())}
            />
        )
    }
}

export default TextInput
