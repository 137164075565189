import React, {Component} from 'react'
import styles from './tabs.module.scss'
import Spinner from '../../components/Spinner/Spinner'

class Tabs extends Component {
    constructor(props) {
        super(props)
    }

    renderTabs = () => {
        return Object.keys(this.props.tabs).map(label => (
            <div
                key={label}
                onClick={() => {
                    this.props.setActiveTab(label)
                }}
                className={`${this.props.activeTab === label ? styles.active : ''} ${styles.tab}`}>
                {label}
            </div>
        ))
    }

    render() {
        return (
            <div>
                <div className={styles.tabs}> {this.renderTabs()}</div>
                {this.props.isLoading ? (
                    <>
                        <Spinner />
                    </>
                ) : (
                    <div className="content">
                        {this.props.activeTab && this.props.tabs[this.props.activeTab].children}
                    </div>
                )}
            </div>
        )
    }
}

export default Tabs
