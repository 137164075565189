import axios from 'axios'
import {handleError} from '../../helpers'
import {store} from '../../App'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_EMPLOYEES = 'LOAD_EMPLOYEES'
export const LOAD_EMPLOYEES_SUCCESS = 'LOAD_EMPLOYEES_SUCCESS'
export const LOAD_EMPLOYEES_FAILURE = 'LOAD_EMPLOYEES_FAILURE'

export const START_EMPLOYEE_EDIT = 'START_EMPLOYEE_EDIT'

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS'
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE'

export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE'
export const EDIT_EMPLOYEE_SUCCESS = 'EDIT_EMPLOYEE_SUCCESS'
export const EDIT_EMPLOYEE_FAILURE = 'EDIT_EMPLOYEE_FAILURE'

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS'
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE'

export const loadEmployees = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: LOAD_EMPLOYEES})
        axios
            .get(
                `${baseUrl}/users?populate=contractType&filters[company]=${store.getState().auth.user.company.id}`,
                config,
            )
            .then(response => {
                dispatch(loadEmployeesSuccess(response.data))
            })
            .catch(error => dispatch(loadEmployeesFailure(error?.response?.data?.error)))
    }
}

export const loadEmployeesSuccess = employees => {
    return {
        type: LOAD_EMPLOYEES_SUCCESS,
        employees,
    }
}

export const loadEmployeesFailure = error => {
    handleError(error)
    return {
        type: LOAD_EMPLOYEES_FAILURE,
        error: error,
    }
}

export const startEmployeeEdit = employee => {
    return {
        type: START_EMPLOYEE_EDIT,
        employee,
    }
}

export const editEmployee = (employee, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: EDIT_EMPLOYEE})
        axios
            .put(`${baseUrl}/users/${employee.id}?populate=contractType`, employee, config)
            .then(response => {
                onSuccess()
                dispatch(editEmployeeSuccess(response.data))
            })
            .catch(error => {
                onError()
                dispatch(editEmployeeFailure(error.response.data.error))
            })
    }
}

export const editEmployeeSuccess = employee => {
    return {
        type: EDIT_EMPLOYEE_SUCCESS,
        employee,
    }
}

export const editEmployeeFailure = error => {
    handleError(error)
    return {
        type: EDIT_EMPLOYEE_FAILURE,
        error,
    }
}

export const deleteEmployee = (employeeId, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: DELETE_EMPLOYEE})
        axios
            .delete(`${baseUrl}/users/${employeeId}`, config)
            .then(() => {
                onSuccess()
                dispatch(deleteEmployeeSuccess(employeeId))
            })
            .catch(error => {
                onError()
                dispatch(deleteEmployeeFailure(error.response.data.error))
            })
    }
}

export const deleteEmployeeSuccess = employeeId => {
    return {
        type: DELETE_EMPLOYEE_SUCCESS,
        employeeId,
    }
}

export const deleteEmployeeFailure = error => {
    handleError(error)
    return {
        type: DELETE_EMPLOYEE_FAILURE,
        error: error,
    }
}

export const addEmployee = (employee, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: ADD_EMPLOYEE})
        axios
            .post(`${baseUrl}/auth/local/register`, employee, config)
            .then(response => {
                onSuccess()
                dispatch(addEmployeeSuccess(response.data))
            })
            .catch(error => {
                onError()
                dispatch(addEmployeeFailure(error.response.data.error))
            })
    }
}

export const addEmployeeSuccess = employee => {
    return {
        type: ADD_EMPLOYEE_SUCCESS,
        employee,
    }
}

export const addEmployeeFailure = error => {
    handleError(error)
    return {
        type: ADD_EMPLOYEE_FAILURE,
        error: error,
    }
}
