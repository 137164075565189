import * as actionTypes from '../actions/employees.actions'
import {clone} from 'ramda'

const initialState = {
    employees: [],
    employeeNames: [],
    employeesLoaded: false,
    employeeAdded: true,
}

const employeesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_EMPLOYEES: {
            return {
                ...state,
                employeesLoaded: false,
            }
        }
        case actionTypes.LOAD_EMPLOYEES_SUCCESS: {
            return {
                ...state,
                employeeNames: action.employees.map(employee => ({
                    value: employee.id,
                    label: employee.username,
                })),
                employees: action.employees.map(user => ({
                    ...user,
                    tax: user.tax ? user.tax : 0,
                    additionalCosts: user.additionalCosts ? user.additionalCosts : 0,
                    edited: false,
                })),
                employeesLoaded: true,
            }
        }
        case actionTypes.LOAD_EMPLOYEES_FAILURE: {
            return {
                ...state,
                employeesLoaded: true,
                employees: [],
                employeeNames: [],
            }
        }

        case actionTypes.START_EMPLOYEE_EDIT: {
            const newEmployees = clone(state.employees)
            const employeeIndex = newEmployees.findIndex(employee => employee.id === action.employee.id)
            newEmployees[employeeIndex] = {
                ...action.employee,
                contractType: {
                    id: action.employee.contractType?.id,
                    name: action.employee.contractType?.name,
                },
                edited: true,
            }

            return {
                ...state,
                employees: newEmployees,
            }
        }

        case actionTypes.EDIT_EMPLOYEE: {
            return {
                ...state,
            }
        }
        case actionTypes.EDIT_EMPLOYEE_SUCCESS: {
            const newEmployees = clone(state.employees)
            const employeeIndex = newEmployees.findIndex(employee => employee.id === action.employee.id)
            newEmployees[employeeIndex] = {
                ...action.employee,
                edited: false,
            }

            return {
                ...state,
                employees: newEmployees,
            }
        }
        case actionTypes.EDIT_EMPLOYEE_FAILURE: {
            return {
                ...state,
            }
        }

        case actionTypes.DELETE_EMPLOYEE: {
            return {
                ...state,
            }
        }
        case actionTypes.DELETE_EMPLOYEE_SUCCESS: {
            const newEmployees = clone(state.employees)
            const employees = newEmployees.filter(employee => employee.id !== action.employeeId)

            return {
                ...state,
                employees,
            }
        }
        case actionTypes.DELETE_EMPLOYEE_FAILURE: {
            return {
                ...state,
                employeesLoaded: true,
            }
        }

        case actionTypes.ADD_EMPLOYEE: {
            return {
                ...state,
                employeeAdded: false,
                loading: true,
            }
        }
        case actionTypes.ADD_EMPLOYEE_SUCCESS: {
            const newEmployees = clone(state.employees)
            newEmployees.push(action.employee)

            return {
                ...state,
                employees: newEmployees,
            }
        }
        case actionTypes.ADD_EMPLOYEE_FAILURE: {
            return {
                ...state,
                loading: false,
            }
        }
        default:
            return state
    }
}

export default employeesReducer
