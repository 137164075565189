import React from 'react'

const ConfirmModal = props => {
    const onConfirm = () => {
        props.confirm()
    }
    return (
        <div className="modal-content">
            <button type="button" className="modal-close" aria-label="Close" onClick={props.closeModal}>
                <span aria-hidden="true">&times;</span>
            </button>
            <p className="mb-5">{props.message}</p>
            <button
                type="button"
                className="action-button action-button-rectangle action-button-pink"
                onClick={onConfirm}>
                Confirm
            </button>
        </div>
    )
}

export default ConfirmModal
