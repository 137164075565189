import * as actionTypes from '../actions/modals.actions'

const initialState = {
    modalType: null,
    modalProps: {
        open: false,
    },
}

const modalsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_MODAL:
            return {
                modalProps: action.modalProps,
                modalType: action.modalType,
                type: action.type,
            }
        case actionTypes.HIDE_MODAL:
            return initialState
        default:
            return state
    }
}

export default modalsReducer
