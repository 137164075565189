import React, {Component} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './customDatePicker.scss'

class CustomDatePicker extends Component {
    constructor(props) {
        super(props)
        this.calendar = React.createRef()
        this.state = {startDate: new Date(this.props.value)}
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({startDate: new Date(this.props.value)})
        }
    }

    componentDidMount() {
        if (this.props.isOpen) {
            this.openDatepicker()
        }
    }

    openDatepicker = () => this.calendar.current.setOpen(true)

    render() {
        return (
            <DatePicker
                ref={this.calendar}
                dateFormat="dd/MM/yyyy"
                selected={this.state.startDate}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                onChange={this.props.onChange}
                calendarStartDay={1}
                disabledKeyboardNavigation={true}
            />
        )
    }
}

export default CustomDatePicker
