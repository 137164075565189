/* eslint-disable react/prop-types */
import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import './datagrid.modules..scss'
import cellEditFactory from 'react-bootstrap-table2-editor'

const dataGrid = props => {
    let rows = [...props.data]

    return (
        <BootstrapTable
            keyField="id"
            data={rows}
            columns={props.columns}
            bodyClasses={'table-body'}
            rowClasses={props.rowClasses}
            bordered={false}
            cellEdit={cellEditFactory({
                mode: 'click',
                blurToSave: true,
                afterSaveCell: (oldValue, newValue, row) => props.updated(row),
            })}
        />
    )
}

export default dataGrid
