import React, {Component} from 'react'
import HistoryGrid from '../../components/HistoryGrid/HistoryGrid'

class History extends Component {
    render() {
        return (
            <div className="page-margin my-4">
                <h1 className="mb-5">History</h1>
                <HistoryGrid />
            </div>
        )
    }
}

export default History
