import React from 'react'
import styles from './addEmployeeForm.module.scss'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import SVG from 'react-inlinesvg'
import {store} from '../../../App'
import {noContractType} from '../EmployeesList'

class AddEmployeeForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            username: '',
            email: '',
            blocked: false,
            confirmed: true,
            name: '',
            surname: '',
            vacationDays: 0,
            additionalCosts: 0,
            tax: 0,
            contractType: noContractType,
            hours: [],
            costs: [],
            billingType: 'hourly',
            valid: false,
            company: store.getState().auth.user.company.id,
        }
    }

    formValid = (name, value) => {
        const regex = /^\S+@\S+\.\S+$/

        const empty = ['username', 'email', 'additionalCosts', 'tax', 'vacationDays'].filter(
            elm => elm !== name && this.state[elm] === '',
        )

        if (name === 'email' && !regex.test(value)) {
            return false
        }

        return empty.length === 0 && value !== ''
    }

    handleChange = e => {
        const {name, value} = e.target
        this.setState({[name]: value, valid: this.formValid(name, value)})
    }

    handleContractChange = value => {
        this.setState({
            ...this.state,
            contractType: {
                id: value.value,
                name: value.label,
            },
            valid: this.formValid(),
        })
    }

    add = () => {
        this.props.add(this.state)
    }

    render() {
        return (
            <div className={styles.detailsHeader}>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Username:</p>
                    <input
                        type="text"
                        className={'form-control ' + styles.formControl}
                        name="username"
                        value={this.state.username}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Name:</p>
                    <input
                        type="text"
                        className={'form-control ' + styles.formControl}
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Surname:</p>
                    <input
                        type="text"
                        className={'form-control ' + styles.formControl}
                        name="surname"
                        value={this.state.surname}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>E-mail:</p>
                    <input
                        type="email"
                        className={'form-control ' + styles.formControl}
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Vacation Days:</p>
                    <input
                        type="number"
                        min="0"
                        className={'form-control ' + styles.formControl}
                        name="vacationDays"
                        value={this.state.vacationDays}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Additional Costs:</p>
                    <input
                        type="number"
                        min="0"
                        className={'form-control ' + styles.formControl}
                        name="additionalCosts"
                        value={this.state.additionalCosts}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Tax:</p>
                    <input
                        type="number"
                        min="0"
                        className={'form-control ' + styles.formControl}
                        name="tax"
                        value={this.state.tax}
                        onChange={this.handleChange}
                    />
                </div>
                <div className={styles.controlContainer}>
                    <p className={styles.caption}>Contract type:</p>
                    <CustomSelect
                        selected={noContractType}
                        options={[...this.props.contractTypes, noContractType]}
                        onChange={this.handleContractChange}
                    />
                </div>
                {this.state.valid && (
                    <div className={styles.addButton}>
                        <button className="action-button action-button-circle action-button-pink" onClick={this.add}>
                            <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

export default AddEmployeeForm
