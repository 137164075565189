import React from 'react'
import './SpinnerStyle.css'

class Spinner extends React.Component {
    render() {
        return (
            <div className={'container'}>
                <img
                    style={{marginTop: this.props.margin_top}}
                    className={'spinner'}
                    src={'/assets/spinner.svg'}
                    alt={'loading'}
                />
            </div>
        )
    }
}

Spinner.defaultProps = {
    margin_top: '10%',
}

export default Spinner
