import {sortDate} from '../../helpers/sorter/sorter'
import * as actionTypes from '../actions/vacation.actions'
import {clone} from 'ramda'

const initialState = {
    vacations: [],
    vacationsLoaded: false,
    allVacations: [],
    allVacationsLoaded: false,
}

const vacationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_USER_VACATIONS: {
            return {
                ...state,
                vacationsLoaded: false,
            }
        }
        case actionTypes.LOAD_USER_VACATIONS_SUCCESS: {
            action.vacations.sort(sortDate('start'))

            return {
                ...state,
                vacationsLoaded: true,
                vacations: action.vacations,
            }
        }
        case actionTypes.LOAD_USER_VACATIONS_FAILURE: {
            return {
                ...state,
                vacationsLoaded: true,
                vacations: [],
            }
        }

        case actionTypes.LOAD_ALL_VACATIONS: {
            return {
                ...state,
                allVacationsLoaded: false,
            }
        }
        case actionTypes.LOAD_ALL_VACATIONS_SUCCESS: {
            return {
                ...state,
                allVacationsLoaded: true,
                allVacations: action.allVacations,
            }
        }
        case actionTypes.LOAD_ALL_VACATIONS_FAILURE: {
            return {
                ...state,
                allVacationsLoaded: true,
            }
        }

        case actionTypes.ACCEPT_VACATION: {
            return {
                ...state,
            }
        }
        case actionTypes.ACCEPT_VACATION_SUCCESS: {
            let newVacations = clone(state.allVacations)
            const vacationIndex = newVacations.findIndex(vacation => vacation.id === action.vacationId)
            newVacations[vacationIndex].accepted = true

            return {
                ...state,
                allVacations: newVacations,
            }
        }
        case actionTypes.ACCEPT_VACATION_FAILURE: {
            return {
                ...state,
            }
        }

        case actionTypes.ADD_VACATION: {
            return {
                ...state,
                vacationsLoaded: false,
            }
        }
        case actionTypes.ADD_VACATION_SUCCESS: {
            const newVacations = clone(state.vacations)
            newVacations.push(action.vacation)

            newVacations.sort(sortDate('start'))

            return {
                ...state,
                vacations: newVacations,
                vacationsLoaded: true,
            }
        }
        case actionTypes.ADD_VACATION_FAILURE: {
            return {
                ...state,
                vacationsLoaded: true,
            }
        }

        case actionTypes.DELETE_VACATION: {
            return {
                ...state,
                vacationsLoaded: true,
            }
        }
        case actionTypes.DELETE_VACATION_SUCCESS: {
            let newVacations = clone(state.vacations).filter(vacation => vacation.id !== action.vacationId)

            return {
                ...state,
                vacations: newVacations,
                vacationsLoaded: true,
            }
        }
        case actionTypes.DELETE_VACATION_FAILURE: {
            return {
                ...state,
                vacationsLoaded: true,
            }
        }

        case actionTypes.EDIT_VACATION: {
            return {
                ...state,
            }
        }
        case actionTypes.EDIT_VACATION_SUCCESS: {
            const newVacations = clone(state.vacations)

            const vacationIndex = newVacations.findIndex(vacation => vacation.id === action.vacation.id)

            newVacations[vacationIndex] = action.vacation
            newVacations[vacationIndex].edited = false

            newVacations.sort(sortDate('start'))

            return {
                ...state,
                vacations: newVacations,
            }
        }
        case actionTypes.EDIT_VACATION_FAILURE: {
            return {
                ...state,
            }
        }

        case actionTypes.START_EDIT_VACATION: {
            const newVacations = clone(state.vacations)

            const vacationIndex = newVacations.findIndex(vacation => vacation.id === action.vacation.id)

            newVacations[vacationIndex] = {
                ...action.vacation,
                edited: true,
            }

            return {
                ...state,
                vacations: newVacations,
            }
        }

        default:
            return state
    }
}

export default vacationsReducer
