import {toast} from 'react-toastify'

export const handleError = error => {
    let message = 'Wystąpił nieznany błąd'

    if (typeof error === 'string') {
        message = error
    }

    if (error?.message) {
        message = error.message
    }

    if (Array.isArray(error?.details)) {
        message = error?.details[0].messages[0]?.message ?? message
    }

    if (Array.isArray(error)) {
        message = error[0]?.message ?? message
    }

    toast.error(message)
}
