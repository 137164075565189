import React from 'react'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import styles from './projectDetailsHeader.module.scss'
import SVG from 'react-inlinesvg'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {loadProjectTypes, loadProjectStages} from '../../../store/actions/types.actions'
import {hideModal, showModal} from '../../../store/actions/modals.actions'

export const archivedOptions = {
    true: {
        label: 'Yes',
        value: true,
    },
    false: {
        label: 'No',
        value: false,
    },
}

class ProjectDetailsHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            project: {...this.props.project},
            changed: false,
        }
    }

    handleChange = e => {
        const {name, value} = e.target
        this.setState({...this.state, project: {...this.state.project, [name]: value}, changed: true})
    }

    handleArchive = value => {
        this.setState({
            ...this.state,
            project: {...this.state.project, archived: value},
            changed: true,
        })
    }

    handleTypeChange = value => {
        this.setState({
            ...this.state,
            project: {...this.state.project, projectType: value},
            changed: true,
        })
    }

    handleStageChange = value => {
        this.setState({
            ...this.state,
            project: {...this.state.project, projectStage: value},
            changed: true,
        })
    }

    onSave = () => {
        this.props.onSave(this.state.project)
        this.setState({...this.state, changed: false})
    }

    showButton = () => {
        return Object.values(this.state.project).filter(prop => prop === '').length === 0 && this.state.changed
    }

    closeModal() {
        this.props.hideModal()
    }

    showModal() {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
    }

    render() {
        return (
            <div>
                <div className={styles.detailsHeader}>
                    <div className={styles.controlContainer}>
                        <p className={styles.caption + ' caption'}>Name:</p>
                        <input
                            type="text"
                            className={'formControl ' + styles.formControl}
                            name="name"
                            value={this.state.project.name}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className={styles.controlContainer}>
                        <p className={styles.caption + ' caption'}>Archived:</p>
                        <CustomSelect
                            selected={this.state.project.archived}
                            options={[archivedOptions.true, archivedOptions.false]}
                            onChange={this.handleArchive}
                        />
                    </div>
                    <div className={styles.controlContainer}>
                        <p className={styles.caption + ' caption'}>Type:</p>
                        <CustomSelect
                            selected={this.state.project.projectType}
                            options={this.props.projectTypes}
                            onChange={this.handleTypeChange}
                        />
                    </div>
                    <div className={styles.controlContainer}>
                        <p className={styles.caption + ' caption'}>Stage:</p>
                        <CustomSelect
                            selected={this.state.project.projectStage}
                            options={this.props.projectStages}
                            onChange={this.handleStageChange}
                        />
                    </div>
                    <div className={styles.controlContainer}>
                        <p className={styles.caption + ' caption'}>Budget:</p>
                        <input
                            type="number"
                            min="0"
                            className={'formControl ' + styles.formControl}
                            name="budget"
                            value={this.state.project.budget}
                            onChange={this.handleChange}
                        />
                    </div>
                    {this.showButton() ? (
                        <button
                            disabled={false}
                            className="action-button action-button-circle action-button-pink"
                            onClick={this.onSave}>
                            <SVG className="icon-circle-white" src="/assets/icons/tick.svg" />
                        </button>
                    ) : (
                        <button
                            disabled={true}
                            className="action-button action-button-circle action-button-pink"
                            onClick={this.onSave}>
                            <SVG className="icon-circle-white" src="/assets/icons/tick.svg" />
                        </button>
                    )}
                </div>
            </div>
        )
    }
}

ProjectDetailsHeader.propTypes = {
    project: PropTypes.shape({
        name: PropTypes.string.isRequired,
        archived: PropTypes.object.isRequired,
        projectType: PropTypes.object.isRequired,
        budget: PropTypes.number.isRequired,
        projectStage: PropTypes.object.isRequired,
    }),
    projectTypes: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    projectStages: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    onSave: PropTypes.func.isRequired,
    loadProjectTypes: PropTypes.func.isRequired,
    loadProjectStages: PropTypes.func.isRequired,
}

function mapStateToProps() {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        loadProjectTypes: () => dispatch(loadProjectTypes()),
        loadProjectStages: () => dispatch(loadProjectStages()),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsHeader)
