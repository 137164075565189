import React from 'react'
import styles from './Report.module.scss'
import {loadReport} from '../../store/actions/report.actions'
import {connect} from 'react-redux'
import Spinner from '../../components/Spinner/Spinner'

class Report extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.loadReport()
    }

    render() {
        return (
            <div className={styles.container} id="iframe_container">
                {!this.props.reportLoaded ? (
                    <Spinner margin_top={'30%'} />
                ) : !this.props.report ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: '30%',
                        }}>
                        <h1>Report not found</h1>
                        <p>No any report is currently assigned to your account.</p>
                    </div>
                ) : (
                    <div className={styles.container} dangerouslySetInnerHTML={{__html: this.props.report}}></div>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        report: state.report.report,
        reportLoaded: state.report.reportLoaded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadReport: () => dispatch(loadReport()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)
