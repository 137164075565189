export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export const showModal = (modalProps, modalType) => {
    return {
        type: SHOW_MODAL,
        modalProps,
        modalType,
    }
}

export const hideModal = () => {
    return {
        type: HIDE_MODAL,
    }
}
