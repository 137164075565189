import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'
import styles from './menu.module.scss'
import SVG from 'react-inlinesvg'
import {logout} from '../../store/actions/authentication.actions'
import {connect} from 'react-redux'

class Menu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loggedIn: this.props.loggedIn,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loggedIn !== this.props.loggedIn) {
            this.setState(() => {
                return {
                    loggedIn: this.props.loggedIn,
                }
            })
        }
    }

    render() {
        return (
            <div className={styles.menuContainer + ' ' + (this.state.loggedIn ? 'show' : styles.hidden)}>
                <nav className={styles.menu} role="navigation">
                    <div className={styles.logo}>
                        {this.props.user?.companyUrl && <img src={this.props.user?.companyUrl} alt="Logo" />}
                    </div>
                    <div className={styles.logo_small}>
                        <img src="/assets/logo_small.png" alt="Logo" />
                    </div>
                    <div>
                        <NavLink activeClassName={styles.active} to="/dashboard">
                            <SVG className={styles.icon} src="/assets/icons/list.svg" />
                            <span>Timesheet</span>
                        </NavLink>
                        <NavLink activeClassName={styles.active} to="/history">
                            <SVG className={styles.icon} src="/assets/icons/history.svg" />
                            <span>History</span>
                        </NavLink>
                        <NavLink activeClassName={styles.active} to="/vacations">
                            <SVG className={styles.icon} src="/assets/icons/sun.svg" />
                            <span>Leave</span>
                        </NavLink>
                        <NavLink activeClassName={styles.active} to="/report">
                            <SVG className={styles.icon} src="/assets/icons/pdf.svg" />
                            <span>Report</span>
                        </NavLink>
                        {this.props.user && this.props.user.role === 'Administrator' && (
                            <NavLink activeClassName={styles.active} to="/projects">
                                <SVG className={styles.icon} src="/assets/icons/lightbulb.svg" />
                                <span>Projects</span>
                            </NavLink>
                        )}
                        {this.props.user && this.props.user.role === 'Administrator' && (
                            <NavLink activeClassName={styles.active} to="/employees">
                                <SVG className={styles.icon} src="/assets/icons/employees.svg" />
                                <span>Employees</span>
                            </NavLink>
                        )}
                        <div className={styles.logout} onClick={this.props.logout}>
                            <SVG className={styles.icon} src="/assets/icons/logout.svg" />
                            <span>Logout</span>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loggedIn: state.auth.loggedIn,
    user: state.auth.user,
})

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
