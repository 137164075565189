import {sortDate} from '../../helpers/sorter/sorter'
import * as actionTypes from '../actions/timesheet.actions'
import {clone} from 'ramda'

const initialState = {
    hours: [],
    hoursLoaded: false,
}

const timesheetReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_HOURS: {
            return {
                ...state,
                hoursLoaded: false,
            }
        }
        case actionTypes.LOAD_HOURS_SUCCESS: {
            return {
                ...state,
                hours: action.hours,
                hoursLoaded: true,
            }
        }
        case actionTypes.LOAD_HOURS_FAILURE: {
            return {
                ...state,
                hours: [],
                hoursLoaded: true,
            }
        }

        case actionTypes.ADD_HOUR: {
            return {
                ...state,
                hoursLoaded: false,
                hour: null,
            }
        }
        case actionTypes.ADD_HOUR_SUCCESS: {
            let newHours = clone(state.hours)
            newHours.push(action.hour)
            newHours.sort(sortDate('date'))
            return {
                ...state,
                hours: newHours,
                hoursLoaded: true,
                hour: null,
            }
        }
        case actionTypes.ADD_HOUR_FAILURE: {
            return {
                ...state,
                hoursLoaded: true,
                hour: action.hour,
            }
        }

        case actionTypes.EDIT_HOUR: {
            return {
                ...state,
            }
        }
        case actionTypes.EDIT_HOUR_SUCCESS: {
            let newHours = clone(state.hours)
            const hourIndex = state.hours.findIndex(hour => hour.id === action.hour.id)
            newHours[hourIndex] = {...action.hour}
            newHours.sort(sortDate('date'))
            return {
                ...state,
                hours: newHours,
                hoursLoaded: true,
            }
        }
        case actionTypes.EDIT_HOUR_FAILURE: {
            return {
                ...state,
                hoursLoaded: true,
                savingHour: false,
            }
        }

        case actionTypes.DELETE_HOUR: {
            return {
                ...state,
            }
        }

        case actionTypes.DELETE_HOUR_SUCCESS: {
            let newHours = clone(state.hours)
            const hourIndex = state.hours.findIndex(hour => hour.id === action.hourId)
            newHours.splice(hourIndex, 1)
            return {
                ...state,
                hours: newHours,
                hoursLoaded: true,
            }
        }

        case actionTypes.DELETE_HOUR_FAILURE: {
            return {
                ...state,
                hoursLoaded: true,
            }
        }

        default:
            return state
    }
}

export default timesheetReducer
