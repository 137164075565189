import React, {Component} from 'react'
import {connect} from 'react-redux'
import DataGrid from '../../../ui/DataGrid/DataGrid'
import NumberInput from '../../../ui/NumberInput/NumberInput'
import CustomDatePicker from '../../../ui/DatePicker/CustomDatePicker'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import {clone} from 'ramda'
import SVG from 'react-inlinesvg'
import {editHour, loadHours} from '../../../store/actions/timesheet.actions'
import {showModal, hideModal} from '../../../store/actions/modals.actions'
import {toast} from 'react-toastify'
import moment from 'moment'

class HourRow extends Component {
    constructor(props) {
        super(props)

        const columns = [
            {
                dataField: 'project',
                text: 'Project',
                sort: true,
                editable: props.projectEditable ?? true,
                formatter: cell => {
                    return cell?.name ?? '-- PROJEKT USUNIĘTY --'
                },
                editorRenderer: (editorProps, project, hour) => (
                    <CustomSelect
                        {...editorProps}
                        selected={{value: project?.id, label: project?.name}}
                        options={props.projects}
                        onChange={updatedProject => {
                            this.handleUpdate({...hour, project: updatedProject})
                        }}
                    />
                ),
                sortValue: cell => {
                    return cell?.name ?? '-- PROJEKT USUNIĘTY --'
                },
            },
            {
                dataField: 'date',
                sort: true,
                text: 'Date',
                editable: props.dateEditable ?? true,
                formatter: cell => {
                    let dateObj = cell
                    if (typeof cell !== 'object') {
                        dateObj = new Date(cell)
                    }
                    return `${('0' + dateObj.getDate()).slice(-2)}/${('0' + (dateObj.getUTCMonth() + 1)).slice(
                        -2,
                    )}/${dateObj.getUTCFullYear()}`
                },
                editorRenderer: (editorProps, value) => {
                    const date = moment(value)
                    return (
                        <CustomDatePicker
                            isOpen={true}
                            minDate={date.clone().startOf('month').toDate()}
                            maxDate={date.clone().endOf('month').toDate()}
                            {...editorProps}
                            onChange={editorProps.onUpdate}
                            value={value}
                        />
                    )
                },
            },
            {
                dataField: 'task',
                text: 'Task',
                editable: props.taskEditable ?? true,
            },
            {
                dataField: 'description',
                text: 'Description',
                editable: props.descriptionEditable ?? true,
            },
            {
                dataField: 'value',
                text: 'Hours',
                editable: props.hoursEditable ?? true,
                editorRenderer: (editorProps, value) => (
                    <NumberInput className="formControl" onUpdate={editorProps.onUpdate} value={value} type="hour" />
                ),
            },
        ]

        const enableDelete = props.enableDelete ?? true

        if (enableDelete) {
            columns.push({
                dataField: 'delete',
                isDummyField: true,
                text: 'Delete',
                editable: false,
                formatter: (cellContent, row) => {
                    return (
                        <button
                            className="action-button action-button-circle action-button-pink align-right"
                            onClick={() => this.props.handleHourDelete(row)}>
                            <SVG className="icon-circle-white" src="/assets/icons/trash-can.svg" />
                        </button>
                    )
                },
            })
        }

        this.state = {
            columns,
        }
    }

    handleHourUpdateSuccess = () => {
        this.props.hideModal()
        toast.success('Hour updated')
    }

    handleHourUpdateFailure = () => {
        this.props.hideModal()
        this.props.loadHours()
    }

    handleUpdate = hour => {
        const found = this.props.hours.find(h => h.id === hour.id)

        const hourHasBeenChanged =
            found.project?.id !== hour.project?.value ||
            found?.date !== hour?.date ||
            found?.value !== hour?.value ||
            found?.description !== hour?.description ||
            found?.task !== hour?.task

        if (!hourHasBeenChanged) {
            return
        }

        this.props.showModal(
            {
                open: true,
                message: 'Saving changes ...',
            },
            'spinner',
        )

        this.props.editHour(hour, this.handleHourUpdateSuccess, this.handleHourUpdateFailure)
    }

    render() {
        return (
            <DataGrid
                columns={this.state.columns}
                data={clone(this.props.hours)}
                updated={this.handleUpdate}
                rowClasses="form-control-table"
            />
        )
    }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        editHour: (hour, onSuccess, onError) => dispatch(editHour(hour, onSuccess, onError)),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
        loadHours: () => dispatch(loadHours()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HourRow)
