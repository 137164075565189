import React from 'react';
import styles from './SpinnerModalStyle.scss';

const SpinnerModal = props => {
    return (
        <div className={styles.blur}>
            <div className="modal-container">
                <div className="modal-content">
                    <img className={'spinner'} src={'/assets/spinner.svg'} alt={'loading'} />
                    <p className="mb-4">{props.message}</p>
                </div>
            </div>
        </div>
    )
}

export default SpinnerModal
