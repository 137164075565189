import React from 'react'
import styles from './hoursPerProject.module.scss'
import {useHistory} from 'react-router-dom'

const hoursPerProject = ({hours, projectName, isLast, projectId}) => {
    const history = useHistory()

    const handleClick = () => {
        history.push({
            pathname: '/history',
            search: `?q=${projectId}`,
        })
    }

    return (
        <h3 className={`mb-2 mt-2 ${styles.link}`} onClick={() => handleClick()}>
            <span>{projectName}: </span>
            <span className={styles.pink}>{hours}h</span>
            {!isLast ? <span className={styles.dot}></span> : null}
        </h3>
    )
}

export default hoursPerProject
