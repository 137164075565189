import * as actionTypes from '../actions/types.actions'

const initialState = {
    positionsLoaded: false,
    positions: [],
    projectTypesLoaded: false,
    projectTypes: [],
    projectStagesLoaded: false,
    projectStages: [],
    contractTypes: [],
    contractTypesLoaded: false,
}

const typesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_EMPLOYEES_POSITIONS: {
            return {
                ...state,
                positionsLoaded: false,
            }
        }
        case actionTypes.LOAD_EMPLOYEES_POSITIONS_SUCCESS: {
            return {
                ...state,
                positions: action.positions.map(position => ({value: position.id, label: position.name})),
                positionsLoaded: true,
            }
        }
        case actionTypes.LOAD_EMPLOYEES_POSITIONS_FAILURE: {
            return {
                ...state,
                positionsLoaded: true,
                positions: [],
            }
        }

        case actionTypes.LOAD_PROJECT_TYPES: {
            return {
                ...state,
                projectTypesLoaded: false,
            }
        }
        case actionTypes.LOAD_PROJECT_TYPES_SUCCESS: {
            return {
                ...state,
                projectTypes: action.types.map(type => ({value: type.id, label: type.name})),
                projectTypesLoaded: true,
            }
        }
        case actionTypes.LOAD_PROJECT_TYPES_FAILURE: {
            return {
                ...state,
                projectTypesLoaded: true,
                projectTypes: [],
            }
        }

        case actionTypes.LOAD_PROJECT_STAGES: {
            return {
                ...state,
                projectStagesLoaded: false,
            }
        }
        case actionTypes.LOAD_PROJECT_STAGES_SUCCESS: {
            return {
                ...state,
                projectStages: action.stages.map(stage => ({value: stage.id, label: stage.name})),
                projectStagesLoaded: true,
            }
        }
        case actionTypes.LOAD_PROJECT_STAGES_FAILURE: {
            return {
                ...state,
                projectStagesLoaded: true,
                projectStages: [],
            }
        }

        case actionTypes.LOAD_CONTRACT_TYPES: {
            return {
                ...state,
                contractTypesLoaded: false,
            }
        }
        case actionTypes.LOAD_CONTRACT_TYPES_SUCCESS: {
            return {
                ...state,
                contractTypes: action.contracttypes.map(contractTypes => ({
                    value: contractTypes.id,
                    label: contractTypes.name,
                })),
                contractTypesLoaded: true,
            }
        }
        case actionTypes.LOAD_CONTRACT_TYPES_FAILURE: {
            return {
                ...state,
                contractTypesLoaded: true,
                contractTypes: [],
            }
        }

        default:
            return state
    }
}

export default typesReducer
