import axios from 'axios'
import {handleError} from '../../helpers'
import {store} from '../../App'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_EMPLOYEES_POSITIONS = 'LOAD_EMPLOYEES_POSITIONS'
export const LOAD_EMPLOYEES_POSITIONS_SUCCESS = 'LOAD_EMPLOYEES_POSITIONS_SUCCESS'
export const LOAD_EMPLOYEES_POSITIONS_FAILURE = 'LOAD_EMPLOYEES_POSITIONS_FAILURE'

export const LOAD_PROJECT_TYPES = 'LOAD_PROJECT_TYPES'
export const LOAD_PROJECT_TYPES_SUCCESS = 'LOAD_PROJECT_TYPES_SUCCESS'
export const LOAD_PROJECT_TYPES_FAILURE = 'LOAD_PROJECT_TYPES_FAILURE'

export const LOAD_PROJECT_STAGES = 'LOAD_PROJECT_STAGES'
export const LOAD_PROJECT_STAGES_SUCCESS = 'LOAD_PROJECT_STAGES_SUCCESS'
export const LOAD_PROJECT_STAGES_FAILURE = 'LOAD_PROJECT_STAGES_FAILURE'

export const LOAD_CONTRACT_TYPES = 'LOAD_CONTRACT_TYPES'
export const LOAD_CONTRACT_TYPES_SUCCESS = 'LOAD_CONTRACT_TYPES_SUCCESS'
export const LOAD_CONTRACT_TYPES_FAILURE = 'LOAD_CONTRACT_TYPES_FAILURE'

const mapProjectType = projectType => ({
    id: projectType.id,
    name: projectType.attributes.name,
    createdAt: projectType.attributes.createdAt,
    updatedAt: projectType.attributes.updatedAt,
})

const mapProjectStage = projectStage => ({
    id: projectStage.id,
    name: projectStage.attributes.name,
    createdAt: projectStage.attributes.createdAt,
    updatedAt: projectStage.attributes.updatedAt,
})

const mapEmployeeLevel = employeeLevel => ({
    id: employeeLevel.id,
    name: employeeLevel.attributes.name,
    createdAt: employeeLevel.attributes.createdAt,
    updatedAt: employeeLevel.attributes.updatedAt,
})

const mapContractType = contractType => ({
    id: contractType.id,
    name: contractType.attributes.name,
    createdAt: contractType.attributes.createdAt,
    updatedAt: contractType.attributes.updatedAt,
})

export const loadPositions = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_EMPLOYEES_POSITIONS})
        axios
            .get(`${baseUrl}/employee-levels`, config)
            .then(response => {
                const mappedEmployeeLevels = response.data.data.map(mapEmployeeLevel)
                dispatch(loadPositionsSuccess(mappedEmployeeLevels))
            })
            .catch(error => dispatch(loadPositionsFailure(error.response.data.data)))
    }
}

export const loadPositionsSuccess = positions => {
    return {
        type: LOAD_EMPLOYEES_POSITIONS_SUCCESS,
        positions,
    }
}

export const loadPositionsFailure = error => {
    handleError(error)
    return {
        type: LOAD_EMPLOYEES_POSITIONS_FAILURE,
        error: error,
    }
}

export const loadProjectTypes = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_PROJECT_TYPES})
        axios
            .get(`${baseUrl}/project-types`, config)
            .then(response => {
                const mappedProjectTypes = response.data.data.map(mapProjectType)
                dispatch(loadProjectTypesSuccess(mappedProjectTypes))
            })
            .catch(error => dispatch(loadprojectTypesFailure(error.response.data.data)))
    }
}

export const loadProjectTypesSuccess = types => {
    return {
        type: LOAD_PROJECT_TYPES_SUCCESS,
        types,
    }
}

export const loadprojectTypesFailure = error => {
    handleError(error)
    return {
        type: LOAD_PROJECT_TYPES_FAILURE,
        error: error,
    }
}

export const loadProjectStages = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_PROJECT_STAGES})
        axios
            .get(`${baseUrl}/project-stages`, config)
            .then(response => {
                const mappedProjectStages = response.data.data.map(mapProjectStage)
                dispatch(loadProjectStagesSuccess(mappedProjectStages))
            })
            .catch(error => dispatch(loadProjectStagesFailure(error.response.data.error)))
    }
}

export const loadProjectStagesSuccess = stages => {
    return {
        type: LOAD_PROJECT_STAGES_SUCCESS,
        stages,
    }
}

export const loadProjectStagesFailure = error => {
    handleError(error)
    return {
        type: LOAD_PROJECT_STAGES_FAILURE,
        error: error,
    }
}

export const loadContractTypes = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_CONTRACT_TYPES})
        axios
            .get(`${baseUrl}/employee-contract-types`, config)
            .then(response => {
                const mappedContractTypes = response.data.data.map(mapContractType)
                dispatch(loadContractTypesSuccess(mappedContractTypes))
            })
            .catch(error => dispatch(loadContractTypesFailure(error.response.data.error)))
    }
}

export const loadContractTypesSuccess = contracttypes => {
    return {
        type: LOAD_CONTRACT_TYPES_SUCCESS,
        contracttypes,
    }
}

export const loadContractTypesFailure = error => {
    handleError(error)
    return {
        type: LOAD_CONTRACT_TYPES_FAILURE,
        error: error,
    }
}
