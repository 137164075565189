import * as actionTypes from '../actions/history.actions'

const initialState = {
    hours: [],
    selectedMonth: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
    historyLoaded: false,
}

const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_HISTORY: {
            return {
                ...state,
                historyLoaded: false,
                hours: [],
                monthWorkingHours: '00:00',
            }
        }

        case actionTypes.LOAD_HISTORY_SUCCESS: {
            return {
                ...state,
                hours: action.hours,
                selectedMonth: action.month,
                selectedYear: action.year,
                historyLoaded: true,
            }
        }

        case actionTypes.LOAD_HISTORY_FAILURE: {
            return {
                ...state,
                historyLoaded: true,
                hours: [],
                monthWorkingHours: '00:00',
            }
        }

        default:
            return state
    }
}

export default historyReducer
