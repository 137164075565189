import Select from 'react-dropdown-select'
import React, {Component} from 'react'
import './customSelect.scss'

class CustomSelect extends Component {
    constructor(props) {
        super(props)
        this.selectRef = React.createRef()
    }

    render() {
        return (
            <div>
                <Select
                    ref={this.selectRef}
                    values={[this.props.selected]}
                    options={this.props.options}
                    searchable={!!this.props.searchable}
                    labelField="label"
                    onChange={values => this.props.onChange(values[0])}
                />
            </div>
        )
    }
}

export default CustomSelect
