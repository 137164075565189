import React, {Component} from 'react'
import MonthGrid from '../monthGrid/MonthGrid'
import moment from 'moment'
import {connect} from 'react-redux'

class TwoMonthsGrid extends Component {
    constructor(props) {
        super(props)
        this.state = {
            projects: props.projects.map(project => ({value: project.id, label: project.name})),
        }
    }

    render() {
        const currentMonthDate = moment()
        const previousMonthDate = moment().subtract(1, 'M').endOf('month')

        const hours = this.props.hours.filter(hour => moment(hour.date).isSame(currentMonthDate, 'month'))
        const previousMonthHours = this.props.hours.filter(hour => moment(hour.date).isSame(previousMonthDate, 'month'))

        return (
            <div>
                <MonthGrid
                    handleHourDelete={this.props.handleHourDelete}
                    projects={this.state.projects}
                    hours={hours}
                    date={currentMonthDate}
                    hour={currentMonthDate.isSame(this.props.hour?.date, 'month') ? this.props.hour : null}
                />
                <div className="mt-6" />
                <MonthGrid
                    handleHourDelete={this.props.handleHourDelete}
                    projects={this.state.projects}
                    hours={previousMonthHours}
                    date={previousMonthDate}
                    hour={previousMonthDate.isSame(this.props.hour?.date, 'month') ? this.props.hour : null}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        hour: state.timesheet.hour,
    }
}

export default connect(mapStateToProps)(TwoMonthsGrid)
