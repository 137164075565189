import React from 'react'
import HoursPerProject from '../HoursPerProject/HoursPerProject'
import styles from './hoursSummary.module.scss'

const hoursSummary = ({hours}) => {
    const hoursPerProjectObj = hours.reduce((acc, hour) => {
        if (!hour?.project?.name) {
            return acc
        }

        if (acc[hour?.project?.name]) {
            acc[hour?.project?.name].hours += hour?.value
        } else {
            acc[hour?.project?.name] = {hours: hour?.value, project: hour?.project}
        }

        return acc
    }, {})

    const hoursPerProject = Object.entries(hoursPerProjectObj).map(([projectName, {hours, project}], index) => ({
        projectName: projectName,
        projectId: project?.id,
        hours,
        isLast: index === Object.keys(hoursPerProjectObj).length - 1,
    }))

    return (
        <div className={styles.container}>
            {hoursPerProject.map(hoursPerProject => (
                <HoursPerProject key={hoursPerProject.projectId} {...hoursPerProject} />
            ))}
        </div>
    )
}

export default hoursSummary
