import * as actionTypes from '../actions/authentication.actions'

let user = JSON.parse(localStorage.getItem('user'))
const initialState = {
    loggedIn: !!user,
    loading: false,
    user: user,
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
        case actionTypes.PASSWORD_RECOVER:
        case actionTypes.PASSWORD_RESET:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loading: false,
                user: action.user,
            }
        case actionTypes.GET_ME_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                loading: false,
                user: action.user,
            }
        case actionTypes.GET_ME_FAILURE:
            return {
                ...state,
                loggedIn: false,
                loading: false,
                user: null,
            }

        case actionTypes.LOGIN_FAILURE:
        case actionTypes.PASSWORD_RECOVER_SUCCESS:
        case actionTypes.PASSWORD_RECOVER_FAILURE:
        case actionTypes.PASSWORD_RESET_SUCCESS:
        case actionTypes.PASSWORD_RESET_FAILURE:
            return {
                ...state,
                loading: false,
            }
        case actionTypes.LOGOUT:
            return {
                ...state,
                loggedIn: false,
                user: null,
            }
        default:
            return state
    }
}

export default authReducer
