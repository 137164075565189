import React, {Component} from 'react'
import {dayNames} from '../../../helpers'
import TwoPartTitle from '../../../ui/TwoPartTitle/TwoPartTitle'
import NewHour from '../NewHour/NewHour'
import HourRow from '../HourRow/HourRow'
import {connect} from 'react-redux'

class WeekGrid extends Component {
    constructor(props) {
        super(props)

        const projects = props.projects.map(project => ({value: project.id, label: project.name}))

        this.state = {
            projects,
        }
    }

    createGrid = () => {
        let children = []

        const hourFromFailure = this.props.hour

        for (let i = 0; i < 7; i++) {
            const data = this.props.hours.filter(
                hour => new Date(hour.date).setHours(0, 0, 0, 0) === this.getPrevDate(i).setHours(0, 0, 0, 0),
            )
            const hourCount = data.map(hour => hour.value).reduce((a, b) => a + b, 0)

            const date = this.getPrevDate(i)

            const projectIsSameDate =
                hourFromFailure && new Date(hourFromFailure.date).setHours(0, 0, 0, 0) === date.setHours(0, 0, 0, 0)

            children.push(
                <div key={i} className="mb-6 hourGrid">
                    <TwoPartTitle
                        firstPart={dayNames[(new Date().getDay() + 7 - i) % 7]}
                        secondPart={hourCount + 'h'}
                    />
                    {!!data.length && (
                        <HourRow
                            hours={data}
                            handleHourDelete={this.props.handleHourDelete}
                            dateEditable={false}
                            projects={this.state.projects}
                        />
                    )}
                    <NewHour
                        projects={this.state.projects}
                        date={date}
                        dateEditable={false}
                        hour={projectIsSameDate ? hourFromFailure : null}
                    />
                </div>,
            )
        }
        return children
    }

    getPrevDate = offset => {
        const date = new Date()
        date.setDate(date.getDate() - offset)
        return date
    }

    render() {
        return (
            <div className="WeekGrid">
                <div>{this.createGrid()}</div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        hour: state.timesheet.hour,
    }
}

export default connect(mapStateToProps)(WeekGrid)
