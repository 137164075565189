import React from 'react'
import {connect} from 'react-redux'
import Modal from 'react-modal'
import {default as modalTypes} from '../components/Modals'

const mapStateToProps = state => ({
    ...state.modals,
})

const MODAL_TYPES = {
    confirm: modalTypes.confirmModal,
    spinner: modalTypes.spinnerModal,
}

class ModalContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            modalIsOpen: props.modalProps.open,
        }
        this.closeModal = this.closeModal.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.modalProps.open !== this.props.modalProps.open) {
            this.setState({
                modalIsOpen: this.props.modalProps.open,
            })
        }
    }

    closeModal() {
        this.props.hideModal()
    }

    render() {
        if (!this.props.modalType) {
            return null
        }
        const SpecifiedModal = MODAL_TYPES[this.props.modalType]
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    className="modal-dialog"
                    ariaHideApp={false}>
                    <SpecifiedModal closeModal={this.closeModal} {...this.props.modalProps} />
                </Modal>
            </div>
        )
    }
}

export default connect(mapStateToProps, null)(ModalContainer)
