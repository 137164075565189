import React from 'react'
import CustomSelect from '../../../ui/CustomSelect/CustomSelect'
import CustomDatePicker from '../../../ui/DatePicker/CustomDatePicker'
import SVG from 'react-inlinesvg'
import styles from './newHour.module.scss'
import '../../../scss/internal/forms.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import {connect} from 'react-redux'
import {hideModal, showModal} from '../../../store/actions/modals.actions'
import {addHour, loadHours} from '../../../store/actions/timesheet.actions'
import {toast} from 'react-toastify'

class NewHour extends React.Component {
    constructor(props) {
        super(props)

        if (props.hour) {
            this.state = {
                ...props.hour,
            }

            return
        }

        const project = this.props.projects.length > 0 ? this.props.projects[0] : {value: '', label: ''}

        this.state = {
            project,
            task: '',
            description: '',
            value: '',
            date: this.props.date,
        }
    }

    handleAddSuccess = () => {
        toast.success('Hour added')
    }

    handleAddFailure = errorMessage => {
        this.props.showModal(
            {
                open: true,
                message: `An error ocurred: ${errorMessage}`,
                confirm: () => this.props.hideModal(),
            },
            'confirm',
        )
    }

    handleChange = e => {
        const {name, value} = e.target
        this.setState({[name]: value})
    }

    handleNumberChange = e => {
        let {name, value} = e.target
        // eslint-disable-next-line no-useless-escape
        const regex = /^[0-9]*(\.|\,)?[0-9]*$/
        if (value === '') {
            this.setState({[name]: ''})
        } else if (regex.test(value) && parseFloat(value) < 12) {
            this.setState({[name]: parseFloat(value)})
        } else if (regex.test(value) && parseFloat(value) > 12) {
            this.setState({[name]: 12})
        }
    }

    handleBlur = e => {
        let {name, value} = e.target
        value = parseFloat(value)
        value = value ? Math.round(value / 0.25) * 0.25 : 0.5
        this.setState({[name]: value})
    }

    handleDateChange = value => {
        this.setState({
            ...this.state,
            date: value,
        })
    }

    addHour = () => {
        this.props.addHour(this.state, this.handleAddSuccess, this.handleAddFailure)
    }

    keyPressed = event => {
        if (event.key === 'Enter') {
            this.addHour()
        }
    }

    render() {
        return (
            <div>
                <h3 className={styles.title}>New entry:</h3>
                <div className={styles.hourForm + ' d-flex a-end my-4'}>
                    <div className="mr-4 f-shrink">
                        <p className="caption">Project</p>
                        <CustomSelect
                            className="formControl"
                            selected={this.state.project}
                            options={this.props.projects}
                            onChange={value =>
                                this.setState({
                                    ...this.state,
                                    project: value,
                                })
                            }
                        />
                    </div>
                    {this.props.dateEditable ? (
                        <div className="mr-4">
                            <p className="caption">Date</p>
                            <CustomDatePicker
                                value={this.state.date}
                                minDate={this.props.date.clone().startOf('M').toDate()}
                                maxDate={this.props.date.clone().endOf('M').toDate()}
                                onChange={this.handleDateChange}
                            />
                        </div>
                    ) : (
                        <div className="d-flex a-center mr-4">
                            <p>
                                {('0' + this.state.date.getDate()).slice(-2)}/
                                {('0' + (this.state.date.getUTCMonth() + 1)).slice(-2)}/
                                {this.state.date.getUTCFullYear()}
                            </p>
                        </div>
                    )}
                    <div className="mr-4 max-w-small">
                        <p className="caption">Hours</p>
                        <input
                            type="number"
                            min="0"
                            className="formControl w-100"
                            name="value"
                            value={this.state.value}
                            onChange={this.handleNumberChange}
                            onBlur={this.handleBlur}
                        />
                    </div>
                    <div className="mr-4 max-w-medium">
                        <p className="caption">Task</p>
                        <input
                            type="text"
                            onKeyPress={this.keyPressed}
                            className="formControl w-100"
                            name="task"
                            value={this.state.task}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="mr-4 f-grow">
                        <p className="caption">Description</p>
                        <input
                            type="text"
                            onKeyPress={this.keyPressed}
                            className="formControl w-100"
                            name="description"
                            value={this.state.description}
                            onChange={this.handleChange}
                        />
                    </div>
                    <button
                        className="action-button action-button-circle action-button-pink"
                        onClick={this.addHour}
                        disabled={
                            !this.state.project || !this.state.date || !this.state.value || !this.state.description
                        }>
                        <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => {
    return {
        loadHours: () => dispatch(loadHours()),
        addHour: (hour, onSuccess, onError) => dispatch(addHour(hour, onSuccess, onError)),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewHour)
