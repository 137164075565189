import React from 'react'
import styles from './twoPartTitle.module.scss'

const twoPartTitle = props => {
    return (
        <h2 className="mb-2">
            <span>{props.firstPart}</span>
            <span className={styles.dot}></span>
            <span className={styles.pink}>{props.secondPart}</span>
        </h2>
    )
}

export default twoPartTitle
