import React, {Component} from 'react'
import {monthNames} from '../../helpers'
import TwoPartTitle from '../../ui/TwoPartTitle/TwoPartTitle'
import range from 'ramda/src/range'
import reverse from 'ramda/src/reverse'
import CustomSelect from '../../ui/CustomSelect/CustomSelect'
import './HistoryGrid.scss'
import {connect} from 'react-redux'
import {loadHistory} from '../../store/actions/history.actions'
import Spinner from '../Spinner/Spinner'
import HourRow from '../Timesheet/HourRow/HourRow'
import TextInput from '../../ui/TextInput/TextInput'

export const filterEmptyProject = {
    label: '-',
    value: '',
}

class HistoryGrid extends Component {
    constructor(props) {
        super(props)

        const months = monthNames.map((name, id) => {
            return {label: name, value: id}
        })

        const y = reverse(range(2016, new Date().getFullYear() + 1))
        const years = y.map(name => {
            return {label: name, value: name}
        })

        this.state = {
            months,
            years,
            project: filterEmptyProject,
            task: '',
        }
    }

    componentDidMount() {
        this.props.loadHistory(this.props.selectedMonth, this.props.selectedYear)
    }

    componentDidUpdate(prevProps) {
        if (this.props.historyLoaded && !prevProps.historyLoaded) {
            this.getProjectFromQuery()
        }
    }

    getProjectFromQuery = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const projectFromQuery = queryParams.get('q')

        if (!projectFromQuery) {
            return
        }

        const projectsMap = this.getUniqueProjects(this.props.hours)

        const projects = projectsMap.map(project => ({value: project.id, label: project.name}))

        const project = projects.find(project => project.value === parseInt(projectFromQuery))

        if (project) {
            this.setState({project: project})
            window.history.replaceState(null, '', window.location.pathname)
        }
    }

    filterHours = hours => {
        let hoursCopy = [...hours]

        if (this.state.project.value) {
            hoursCopy = hoursCopy.filter(hour => hour.project?.id === this.state.project.value)
        }

        if (this.state.task) {
            hoursCopy = hoursCopy.filter(hour => hour.task?.toLowerCase().includes(this.state.task.toLowerCase()))
        }

        return hoursCopy
    }

    getUniqueProjects = hours => {
        const projectsMap = new Map()

        for (const hour of hours) {
            if (!hour.project?.id) {
                continue
            }

            projectsMap.set(hour.project.id, hour.project)
        }

        return Array.from(projectsMap.values())
    }

    onYearChange = year => {
        this.setState({projects: []})
        this.props.loadHistory(this.props.selectedMonth, year.value, filterEmptyProject)
    }

    onMonthChange = month => {
        this.setState({projects: []})
        this.props.loadHistory(month.value, this.props.selectedYear, filterEmptyProject)
    }

    onProjectChange = project => {
        this.setState({project: project})
    }

    onTaskChange = task => {
        this.setState({task: task})
    }

    render() {
        if (!this.props.historyLoaded) return <Spinner />

        const projects = this.getUniqueProjects(this.props.hours)
        const hours = this.filterHours(this.props.hours)

        const monthWorkingHours = hours.map(hour => hour.value).reduce((a, b) => a + b, 0)

        return (
            <div className="hourGrid">
                <div className="row">
                    <div className="mb-2">
                        <TwoPartTitle
                            firstPart={monthNames[this.props.selectedMonth]}
                            secondPart={`${monthWorkingHours} h`}
                        />
                    </div>

                    <div className="filters">
                        <div className="mr-3">
                            <p className="filter-label">Project</p>
                            <CustomSelect
                                options={[
                                    filterEmptyProject,
                                    ...projects.map(project => ({value: project.id, label: project.name})),
                                ]}
                                selected={this.state.project}
                                onChange={this.onProjectChange}
                            />
                        </div>
                        <div className="mr-3">
                            <p className="filter-label">Month</p>
                            <CustomSelect
                                onChange={this.onMonthChange}
                                options={this.state.months}
                                closeOnSelect
                                selected={{
                                    label: monthNames[this.props.selectedMonth],
                                    value: this.props.selectedMonth,
                                }}
                            />
                        </div>
                        <div className="mr-3">
                            <p className="filter-label">Year</p>
                            <CustomSelect
                                options={this.state.years}
                                onChange={this.onYearChange}
                                closeOnSelect
                                selected={{
                                    label: this.props.selectedYear,
                                    value: this.props.selectedYear,
                                }}
                            />
                        </div>
                        <div>
                            <p className="filter-label">Task</p>
                            <TextInput value={this.state.task} onUpdate={this.onTaskChange} />
                        </div>
                    </div>
                </div>
                {this.props.hours.length > 0 ? (
                    <HourRow
                        hours={hours}
                        projectEditable={false}
                        dateEditable={false}
                        taskEditable={false}
                        descriptionEditable={false}
                        hoursEditable={false}
                        enableDelete={false}
                    />
                ) : (
                    <p>No data.</p>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    hours: state.history.hours,
    selectedMonth: state.history.selectedMonth,
    selectedYear: state.history.selectedYear,
    historyLoaded: state.history.historyLoaded,
    monthWorkingHours: state.history.monthWorkingHours,
})

const mapDispatchToProps = dispatch => {
    return {
        loadHistory: (month, year, project) => dispatch(loadHistory(month, year, project)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoryGrid)
