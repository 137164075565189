import axios from 'axios'
import {handleError} from '../../helpers'
import {store} from '../../App'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_REPORT = 'LOAD_REPORT'
export const LOAD_REPORT_SUCCESS = 'LOAD_REPORT_SUCCESS'
export const LOAD_REPORT_FAILURE = 'LOAD_REPORT_FAILURE'

export const loadReport = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_REPORT})
        axios
            .get(`${baseUrl}/users/me`, config)
            .then(response => {
                dispatch(loadReportSuccess(response.data.htmlReport))
            })
            .catch(error => dispatch(loadReportFailure(error.response.data)))
    }
}

export const loadReportSuccess = report => {
    return {
        type: LOAD_REPORT_SUCCESS,
        report: report,
    }
}

export const loadReportFailure = error => {
    handleError(error)
    return {
        type: LOAD_REPORT_FAILURE,
        error: error,
    }
}
