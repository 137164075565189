import {store} from '../../App'
import axios from 'axios'
import {handleError} from '../../helpers'
import {toast} from 'react-toastify'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_USER_VACATIONS = 'LOAD_USER_HOLDAYS'
export const LOAD_USER_VACATIONS_SUCCESS = 'LOAD_USER_VACATIONS_SUCCESS'
export const LOAD_USER_VACATIONS_FAILURE = 'LOAD_USER_VACATIONS_FAILURE'

export const LOAD_ALL_VACATIONS = 'LOAD_ALL_VACATIONS'
export const LOAD_ALL_VACATIONS_SUCCESS = 'LOAD_ALL_VACATIONS_SUCCESS'
export const LOAD_ALL_VACATIONS_FAILURE = 'LOAD_ALL_VACATIONS_FAILURE'

export const ACCEPT_VACATION = 'ACCEPT_VACATION'
export const ACCEPT_VACATION_SUCCESS = 'ACCEPT_VACATION_SUCCESS'
export const ACCEPT_VACATION_FAILURE = 'ACCEPT_VACATION_FAILURE'

export const ADD_VACATION = 'ADD_VACATION'
export const ADD_VACATION_SUCCESS = 'ADD_VACATION_SUCCESS'
export const ADD_VACATION_FAILURE = 'ADD_VACATION_FAILURE'

export const DELETE_VACATION = 'DELETE_VACATION'
export const DELETE_VACATION_SUCCESS = 'DELETE_VACATION_SUCCESS'
export const DELETE_VACATION_FAILURE = 'DELETE_VACATION_FAILURE'

export const START_EDIT_VACATION = 'START_EDIT_VACATION'

export const EDIT_VACATION = 'EDIT_VACATION'
export const EDIT_VACATION_SUCCESS = 'EDIT_VACATION_SUCCESS'
export const EDIT_VACATION_FAILURE = 'EDIT_VACATION_FAILURE'

export const loadUserVacations = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_USER_VACATIONS})
        axios
            .get(`${baseUrl}/vacations`, config)
            .then(response => {
                dispatch(loadUserVacationsSuccess(response.data))
            })
            .catch(error => dispatch(loadUserVacationsFailure(error.response.data.error)))
    }
}

export const loadUserVacationsSuccess = vacations => {
    return {
        type: LOAD_USER_VACATIONS_SUCCESS,
        vacations,
    }
}

export const loadUserVacationsFailure = error => {
    handleError(error)
    return {
        type: LOAD_USER_VACATIONS_FAILURE,
        error: error,
    }
}

export const acceptVacation = (vacationId, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: ACCEPT_VACATION})
        axios
            .patch(`${baseUrl}/vacations/${vacationId}/accept`, {}, config)
            .then(() => {
                onSuccess()
                dispatch(acceptVacationSuccess(vacationId))
            })
            .catch(error => {
                onError()
                dispatch(acceptVacationFailure(error.response.data.error))
            })
    }
}

export const acceptVacationSuccess = vacationId => {
    return {
        type: ACCEPT_VACATION_SUCCESS,
        vacationId,
    }
}

export const acceptVacationFailure = error => {
    handleError(error)
    return {
        type: ACCEPT_VACATION_FAILURE,
        error: error,
    }
}

export const loadAllVacations = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: LOAD_ALL_VACATIONS})
        axios
            .get(
                `${baseUrl}/vacations/all-vacations?filters[start][$gte]=${new Date().getFullYear().toString()}-01-01`,
                config,
            )
            .then(response => {
                dispatch(loadAllVacationsSuccess(response.data))
            })
            .catch(error => {
                dispatch(loadAllVacationsFailure(error.response.data.error))
            })
    }
}

export const loadAllVacationsSuccess = allVacations => {
    return {
        type: LOAD_ALL_VACATIONS_SUCCESS,
        allVacations,
    }
}

export const loadAllVacationsFailure = error => {
    handleError(error)
    return {
        type: LOAD_ALL_VACATIONS_FAILURE,
        error: error,
    }
}

export const addVacation = (start, end) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: ADD_VACATION})
        axios
            .post(
                `${baseUrl}/vacations`,
                {
                    start: `${new Date(start).toISOString().slice(0, 10)}`,
                    end: `${new Date(end).toISOString().slice(0, 10)}`,
                },
                config,
            )
            .then(response => {
                dispatch(addVacationSuccess(response.data))
            })
            .catch(error => dispatch(addVacationFailure(error.response.data.error)))
    }
}

export const addVacationSuccess = vacation => {
    toast.success('Vacation added successfully')
    return {
        type: ADD_VACATION_SUCCESS,
        vacation,
    }
}

export const addVacationFailure = error => {
    handleError(error)
    return {
        type: ADD_VACATION_FAILURE,
        error: error,
    }
}

export const deleteVacation = (vacationId, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: DELETE_VACATION})
        axios
            .delete(`${baseUrl}/vacations/${vacationId}`, config)
            .then(() => {
                dispatch(deleteVacationSuccess(vacationId))
                onSuccess()
            })
            .catch(error => {
                onError()
                dispatch(deleteVacationFailure(error.response.data.error))
            })
    }
}

export const deleteVacationSuccess = vacationId => {
    return {
        type: DELETE_VACATION_SUCCESS,
        vacationId,
    }
}

export const deleteVacationFailure = error => {
    handleError(error)
    return {
        type: DELETE_VACATION_FAILURE,
        error: error,
    }
}

export const editVacation = (vacationId, start, end, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: EDIT_VACATION})
        axios
            .put(
                `${baseUrl}/vacations/${vacationId}`,
                {
                    start,
                    end,
                },
                config,
            )
            .then(response => {
                onSuccess()
                dispatch(editVacationSuccess(response.data))
            })
            .catch(error => {
                onError()
                dispatch(editVacationFailure(error.response.data.error))
            })
    }
}

export const editVacationSuccess = vacation => {
    return {
        type: EDIT_VACATION_SUCCESS,
        vacation,
    }
}

export const editVacationFailure = error => {
    handleError(error)
    return {
        type: EDIT_VACATION_FAILURE,
        error: error,
    }
}

export const startVacationEdit = vacation => {
    return {
        type: START_EDIT_VACATION,
        vacation,
    }
}
