import React from 'react'
import EmployeesList from '../../components/Employees/EmployeesList'
import {connect} from 'react-redux'
import {
    loadEmployees,
    startEmployeeEdit,
    editEmployee,
    deleteEmployee,
    addEmployee,
} from '../../store/actions/employees.actions'
import {loadContractTypes} from '../../store/actions/types.actions'
import {showModal, hideModal} from '../../store/actions/modals.actions'
import SVG from 'react-inlinesvg'
import AddEmployeeForm from '../../components/Employees/AddEmployeeForm/AddEmployeeForm'
import Spinner from '../../components/Spinner/Spinner'
import {sortString} from '../../helpers/sorter/sorter'
import {toast} from 'react-toastify'

class Employees extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addPanelVisible: false,
        }
    }

    componentDidMount() {
        this.props.loadEmployees()
        this.props.loadContractTypes()
    }

    onEmployeeUpdate = employee => {
        const foundEmployee = this.props.employees.find(e => e.id === employee.id)

        if (
            foundEmployee.contractType?.id === employee.contractType.id &&
            foundEmployee.tax === employee.tax &&
            foundEmployee.additionalCosts === employee.additionalCosts &&
            foundEmployee.blocked === employee.blocked &&
            foundEmployee.username === employee.username &&
            foundEmployee.vacationDays === employee.vacationDays
        ) {
            return
        }

        this.props.startEmployeeEdit(employee)
    }

    onEmployeeSaveSuccess = () => {
        this.props.hideModal()
        toast.success('Employee saved successfully')
    }

    onEmployeeSaveError = () => {
        this.props.hideModal()
    }

    onEmployeeSave = employee => {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )

        const updatedEmployee = this.props.employees.find(e => e.id === employee.id)

        this.props.editEmployee(
            {
                ...updatedEmployee,
                contractType: updatedEmployee.contractType.id ? updatedEmployee.contractType.id : null,
            },
            this.onEmployeeSaveSuccess,
            this.onEmployeeSaveError,
        )
    }

    onEmployeeDeleteSuccess = () => {
        this.props.hideModal()
        toast.success('Employee deleted successfully')
    }

    onEmployeeDeleteError = () => {
        this.props.hideModal()
    }

    onEmployeeDelete = employeeId => {
        this.props.showModal(
            {
                open: true,
                message: 'Are You sure You want to delete this employee?',
                confirm: () => this.deleteEmployee(employeeId),
            },
            'confirm',
        )
    }

    deleteEmployee = employeeId => {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
        this.props.deleteEmployee(employeeId, this.onEmployeeDeleteSuccess, this.onEmployeeDeleteError)
    }

    onEmployeeAdd = employee => {
        this.props.showModal(
            {
                open: true,
                message: 'Please wait...',
            },
            'spinner',
        )
        this.props.addEmployee(employee, this.onEmployeeAddSuccess, this.onEmployeeAddError)
    }

    onEmployeeAddSuccess = () => {
        this.props.hideModal()
        toast.success('Employee added successfully')
        this.closePanel()
    }

    onEmployeeAddError = () => {
        this.props.hideModal()
    }

    closePanel = () => {
        this.setState({
            ...this.state,
            addPanelVisible: false,
        })
    }

    showPanel = () => {
        this.setState({
            ...this.state,
            addPanelVisible: true,
        })
    }

    sortData() {
        this.props.employees.sort(sortString('username'))
    }

    render() {
        if (!this.props.employeesLoaded || !this.props.contractTypesLoaded) {
            return <Spinner />
        }

        this.sortData()

        return (
            <div className="page-margin my-4">
                <h1 className="mb-5">Employees</h1>
                <EmployeesList
                    employees={this.props.employees}
                    contractTypes={this.props.contractTypes}
                    onUpdate={this.onEmployeeUpdate}
                    onSave={this.onEmployeeSave}
                    onDelete={this.onEmployeeDelete}
                />
                {this.state.addPanelVisible && (
                    <AddEmployeeForm contractTypes={this.props.contractTypes} add={this.onEmployeeAdd} />
                )}
                {!this.state.addPanelVisible && this.props.employeesLoaded && this.props.contractTypesLoaded && (
                    <div className="d-flex">
                        <button
                            className="action-button action-button-circle action-button-pink mx-auto mt-4"
                            onClick={this.showPanel}>
                            <SVG className="icon-circle-white" src="/assets/icons/plus.svg" />
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        employees: state.employees.employees,
        employeesLoaded: state.employees.employeesLoaded,

        contractTypes: state.types.contractTypes,
        contractTypesLoaded: state.types.contractTypesLoaded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadEmployees: () => dispatch(loadEmployees()),
        loadContractTypes: () => dispatch(loadContractTypes()),

        startEmployeeEdit: employee => dispatch(startEmployeeEdit(employee)),
        editEmployee: (employee, onSuccess, onError) => dispatch(editEmployee(employee, onSuccess, onError)),

        deleteEmployee: (employeeId, onSuccess, onError) => dispatch(deleteEmployee(employeeId, onSuccess, onError)),
        addEmployee: (employee, onSuccess, onError) => dispatch(addEmployee(employee, onSuccess, onError)),

        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Employees)
