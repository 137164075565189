import {handleError} from '../../helpers'

import axios from 'axios'
import {store} from '../../App'

const baseUrl = process.env.REACT_APP_API_URL

export const LOAD_PROJECTS = 'LOAD_PROJECTS'
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS'
export const LOAD_PROJECTS_FAILURE = 'LOAD_PROJECTS_FAILURE'

export const LOAD_PROJECT_DETAILS = 'LOAD_PROJECT_DETAILS'
export const LOAD_PROJECT_DETAILS_SUCCESS = 'LOAD_PROJECT_DETAILS_SUCCESS'
export const LOAD_PROJECT_DETAILS_FAILURE = 'LOAD_PROJECT_DETAILS_FAILURE'

export const LOAD_USER_PROJECTS = 'LOAD_USER_PROJECTS'
export const LOAD_USER_PROJECTS_SUCCESS = 'LOAD_USER_PROJECTS_SUCCESS'
export const LOAD_USER_PROJECTS_FAILURE = 'LOAD_USER_PROJECTS_FAILURE'

export const DELETE_PROJECT = 'DELETE_PROJECT'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE'

export const ADD_PROJECT = 'ADD_PROJECT'
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS'
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE'

export const SAVE_PROJECT_CHANGES = 'SAVE_PROJECT_CHANGES'
export const SAVE_PROJECT_CHANGES_SUCCESS = 'SAVE_PROJECT_CHANGES_SUCCESS'
export const SAVE_PROJECT_CHANGES_FAILURE = 'SAVE_PROJECT_CHANGES_FAILURE'

export const DELETE_COST = 'DELETE_COST'
export const DELETE_COST_SUCCESS = 'DELETE_COST_SUCCESS'
export const DELETE_COST_FAILURE = 'DELETE_COST_FAILURE'

export const ADD_COST = 'ADD_COST'
export const ADD_COST_SUCCESS = 'ADD_COST_SUCCESS'
export const ADD_COST_FAILURE = 'ADD_COST_FAILURE'

export const ARCHIVE_COST = 'ARCHIVE_COST'
export const ARCHIVE_COST_SUCCESS = 'ARCHIVE_COST_SUCCESS'
export const ARCHIVE_COST_FAILURE = 'ARCHIVE_COST_FAILURE'

export const loadProjects = () => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        const companyId = store.getState().auth.user.company.id

        dispatch({type: LOAD_PROJECTS})
        axios
            .get(`${baseUrl}/projects?filters[company]=${companyId}&populate=projectType,projectStage`, config)
            .then(response => {
                dispatch(loadProjectsSuccess(response.data))
            })
            .catch(error => dispatch(loadProjectsFailure(error.response.data.error)))
    }
}

export const loadProjectsSuccess = projects => {
    return {
        type: LOAD_PROJECTS_SUCCESS,
        projects: projects,
    }
}

export const loadProjectsFailure = error => {
    handleError(error)
    return {
        type: LOAD_PROJECTS_FAILURE,
        error,
    }
}

export const loadUserProjects = archived => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    let url = `${baseUrl}/projects/my-projects`

    if (typeof archived === 'boolean') {
        url += `?archived=${archived}`
    }

    return dispatch => {
        dispatch({type: LOAD_USER_PROJECTS})
        axios
            .get(url, config)
            .then(response => {
                dispatch(loadUserProjectsSuccess(response.data))
            })
            .catch(error => dispatch(loadUserProjectsFailure(error?.response?.data.error)))
    }
}

export const loadUserProjectsSuccess = projects => {
    return {
        type: LOAD_USER_PROJECTS_SUCCESS,
        projects,
    }
}

export const loadUserProjectsFailure = error => {
    handleError(error)
    return {
        type: LOAD_USER_PROJECTS_FAILURE,
        error: error,
    }
}

export const loadProjectDetails = projectId => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: LOAD_PROJECT_DETAILS})
        axios
            .get(
                `${baseUrl}/projects/${projectId}?populate[0]=projectType&populate[1]=projectStage&populate[2]=costs&populate[3]=costs.employeeLevel&populate[4]=costs.user`,
                config,
            )
            .then(response => {
                dispatch(loadProjectDetailsSuccess(response.data))
            })
            .catch(error => dispatch(loadProjectDetailsFailure(error?.response?.data?.error)))
    }
}

export const loadProjectDetailsSuccess = project => {
    return {
        type: LOAD_PROJECT_DETAILS_SUCCESS,
        project: project,
    }
}

export const loadProjectDetailsFailure = error => {
    handleError(error)
    return {
        type: LOAD_PROJECT_DETAILS_FAILURE,
        error: error,
    }
}

export const deleteProject = (projectId, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: DELETE_PROJECT})
        axios
            .delete(`${baseUrl}/projects/${projectId}`, config)
            .then(response => {
                onSuccess()
                dispatch(deleteProjectSuccess(response.data))
            })
            .catch(error => {
                onError()
                dispatch(deleteProjectFailure(error.response.data.data))
            })
    }
}

export const deleteProjectSuccess = project => {
    return {
        type: DELETE_PROJECT_SUCCESS,
        project,
    }
}

export const deleteProjectFailure = error => {
    handleError(error)
    return {
        type: DELETE_PROJECT_FAILURE,
        error: error,
    }
}

export const addProject = (project, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    const body = {
        data: {
            company: store.getState().auth.user.company.id,
            costs: [],
            ...project,
        },
    }
    return dispatch => {
        dispatch({type: ADD_PROJECT})
        axios
            .post(`${baseUrl}/projects?populate=projectType,projectStage`, body, config)
            .then(response => {
                onSuccess(response.data.data.id)
                dispatch(addProjectSuccess(response.data.data))
            })
            .catch(error => {
                onError()
                dispatch(addProjectFailure(error.response.data.error))
            })
    }
}

export const addProjectSuccess = project => {
    return {
        type: ADD_PROJECT_SUCCESS,
        project,
    }
}

export const addProjectFailure = error => {
    handleError(error)
    return {
        type: ADD_PROJECT_FAILURE,
        error: error,
    }
}

export const saveProjectChanges = (projectId, project, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: SAVE_PROJECT_CHANGES})
        axios
            .put(
                `${baseUrl}/projects/${projectId}?populate=projectType,projectStage,costs,costs.employeeLevel,costs.user`,
                {data: project},
                config,
            )
            .then(() => {
                onSuccess()
                dispatch(saveProjectChangesSuccess())
            })
            .catch(error => {
                onError()
                dispatch(saveProjectChangesFailure(error.response.data.data))
            })
    }
}

export const saveProjectChangesSuccess = () => {
    return {
        type: SAVE_PROJECT_CHANGES_SUCCESS,
    }
}

export const saveProjectChangesFailure = error => {
    handleError(error)
    return {
        type: SAVE_PROJECT_CHANGES_FAILURE,
        error: error,
    }
}

export const saveCostChanges = cost => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    const body = {
        data: {
            internal: cost.internal,
            external: cost.external,
        },
    }

    return dispatch => {
        dispatch({type: SAVE_PROJECT_CHANGES})
        axios
            .put(`${baseUrl}/costs/${cost.id}`, body, config)
            .then(() => {
                dispatch(saveProjectChangesSuccess())
            })
            .catch(error => dispatch(saveProjectChangesFailure(error.response.data.error)))
    }
}

export const deleteCost = (costId, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }
    return dispatch => {
        dispatch({type: DELETE_COST})
        axios
            .delete(`${baseUrl}/costs/${costId}`, config)
            .then(() => {
                onSuccess()
                dispatch(deleteCostSuccess())
            })
            .catch(error => {
                onError()
                dispatch(deleteCostFailure(error.response.data.data))
            })
    }
}

export const deleteCostSuccess = () => {
    return {
        type: DELETE_COST_SUCCESS,
    }
}

export const deleteCostFailure = error => {
    handleError(error)
    return {
        type: DELETE_COST_FAILURE,
        error,
    }
}

export const addCost = (projectId, cost, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    const body = {
        ...cost,
        project: projectId,
        archived: false,
    }

    return dispatch => {
        dispatch({type: ADD_COST})
        axios
            .post(`${baseUrl}/costs`, body, config)
            .then(() => {
                onSuccess()
                dispatch(addCostSuccess())
            })
            .catch(error => {
                onError()
                dispatch(addCostFailure(error.response.data.error))
            })
    }
}

export const addCostSuccess = () => {
    return {
        type: ADD_COST_SUCCESS,
    }
}

export const addCostFailure = error => {
    handleError(error)
    return {
        type: ADD_COST_FAILURE,
        error: error,
    }
}

export const archiveCost = (costId, onSuccess, onError) => {
    const config = {
        headers: {Authorization: `Bearer ${store.getState().auth.user.token}`},
    }

    return dispatch => {
        dispatch({type: ARCHIVE_COST})
        axios
            .put(`${baseUrl}/costs/${costId}/archive`, {}, config)
            .then(() => {
                onSuccess()
                dispatch(archiveCostSuccess(costId))
            })
            .catch(error => {
                onError()
                dispatch(archiveCostFailure(error.response.data.data))
            })
    }
}

export const archiveCostSuccess = costId => {
    return {
        type: ARCHIVE_COST_SUCCESS,
        costId,
    }
}

export const archiveCostFailure = error => {
    handleError(error)
    return {
        type: ARCHIVE_COST_FAILURE,
        error: error,
    }
}
