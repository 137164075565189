import React, {Component} from 'react'
import {connect} from 'react-redux'
import {loadAllVacations} from '../../../store/actions/vacation.actions'
import Spinner from '../../Spinner/Spinner'
import AllVacations from '../AllVacations/AllVacations'

class AdminLeave extends Component {
    componentDidMount() {
        this.props.loadAllVacations()
    }

    render() {
        if (!this.props.allVacationsLoaded) {
            return <Spinner />
        }
        return (
            <>
                <h3 className="mt-4">Manage vacations as admin</h3>
                {this.props.allVacations.length > 0 ? (
                    <AllVacations allVacations={this.props.allVacations} />
                ) : (
                    <p className="mt-3">Not found any vacations</p>
                )}
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        allVacations: state.vacations.allVacations,
        allVacationsLoaded: state.vacations.allVacationsLoaded,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadAllVacations: () => dispatch(loadAllVacations()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLeave)
